import React from 'react'



const TopSelling =()=> {

    return (
      <section className="icon whitelabel-innove pt-100 topsell">
        <div className="container">
          <h3 className="heading-h2 text-center"><span className='bluecolor'>Our Top Selling</span> Crypto Payment Gateway Solutions</h3>
          <p className='text-center'>At Coinsclone, our expert team specializes in creating bespoke cryptocurrency payment gateway solutions designed specifically for your business.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                CoinPayments Clone
                  </h3>
                <p className="pharagraph">Our Coinpayments Clone is a versatile and secure payment processing platform tailored for handling multiple cryptocurrencies. With advanced functionality for seamless transactions, high-level security protocols, and an intuitive user interface, our Coinpayments Clone ensures businesses can efficiently handle crypto payments while providing an exceptional customer experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                CoinGate Clone
                </h3>
                <p className="pharagraph">The Coingate Clone is an advanced payment gateway designed to mirror the extensive capabilities of the original Coingate platform. Our Coingate Clone empowers merchants to accept payments globally with ease. It offers a complete suite of tools for managing transactions, integrating with various e-commerce platforms, and providing a seamless payment experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                BitPay Clone
                </h3>
                <p className="pharagraph">Our Bitpay Clone offers a powerful payment processing solution modeled after the original Bitpay platform. Our Bitpay Clone provides a reliable and scalable option for merchants looking to integrate cryptocurrency payments into their operations. It ensures a smooth and secure transaction experience for both businesses and their customers.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NOWPayments Clone
                </h3>
                <p className="pharagraph">The NOWPayments Clone is a sophisticated payment gateway solution designed to replicate the features of the original NOWPayments platform. With a focus on ease of integration, security, and user experience, our NOWPayments Clone enables merchants to seamlessly incorporate cryptocurrency payments into their business models and expand their payment options.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Coinbase Commerce Clone
                </h3>
                <p className="pharagraph mb-0">Our Coinbase Commerce Clone provides a reliable and secure payment processing solution that emulates the features of the original Coinbase Commerce platform. With a focus on ease of use and comprehensive support for multiple digital assets, the Coinbase Commerce Clone ensures that businesses can efficiently manage crypto transactions and enhance their payment capabilities.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                CoinsPaid Clone
                </h3>
                <p className="pharagraph">Our Coinspaid Clone is a versatile and high-performance payment gateway designed to emulate the extensive features of the original Coinspaid platform. Our Coinspaid Clone is an ideal solution for businesses looking to expand their payment options and integrate cryptocurrency payments seamlessly.</p>
              </div>
              </div>
          </div>
        </div>
      </section>
    )

}

export default TopSelling