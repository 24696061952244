import React from 'react';

import { Helmet } from "react-helmet"
import BannerSection from "../components/Cryptopayment/Banner"
import Pickcrypto from "../components/Cryptopayment/Pickcrypto"
import Securefeaof from "../components/Cryptopayment/Securefeaof"
import Whychoose from "../components/Cryptopayment/Whychoose"
import TechStack from "../components/Cryptopayment/TechStack"
import UserFriendly from "../components/Cryptopayment/UserFriendly"
import FaqSection from '../components/Cryptopayment/FaqSection';
import Whatis from '../components/Cryptopayment/Whatis';
import Useradmin from '../components/Cryptopayment/Useradmin';
import Layout from '../components/layout';
import PaymentOption from '../components/Cryptopayment/PaymentOption';
import DevProcess from '../components/Cryptopayment/DevProcess';
import ServiceProvider from '../components/Cryptopayment/ServiceProvider';
import BenifitsOf from '../components/Cryptopayment/BenifitsOf';
import WhyStart from '../components/Cryptopayment/WhyStart';
import TopSelling from '../components/Cryptopayment/TopSelling';
import WhatsNew from '../components/Cryptopayment/WhatsNew';
import Integrations from '../components/Cryptopayment/Integrations';


const webSchema = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "@id": "#WebPage",         
  "url": "https://www.coinsclone.com/cryptocurrency-payment-gateway/",          
  "name": "Crypto Payment Gateway Development"
};
console.log("schema  ", JSON.stringify(webSchema));

const Cryptopayment = () => (
  <section className="cryptoex">
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/cryptocurrency-payment-gateway/" />
        <title>Cryptocurrency Payment Gateway Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone, a world class crypto payment gateway development company offers impactful solutions for all startups who wish to launch an ideal payment gateway." />
        <meta name="keywords" content="Crypto Payment Gateway Development Company, cryptocurrency payment gateway development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cryptocurrency Payment Gateway Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone, a world class crypto payment gateway development company offers impactful solutions for all startups who wish to launch an ideal payment gateway." />
        <meta property="og:url" content="https://www.coinsclone.com/cryptocurrency-payment-gateway/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/cryptopayment/crypto-payment-gateway.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone, a world class crypto payment gateway development company offers impactful solutions for all startups who wish to launch an ideal payment gateway." />
        <meta name="twitter:title" content="Cryptocurrency Payment Gateway Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/cryptopayment/crypto-payment-gateway.webp" />
        <link rel="preload" fetchpriority="low" as="image" href="https://coinsclone.mo.cloudinary.net/images/cryptopayment/crypto-payment-gateway.webp" type="image/webp" />
        <script type="application/ld+json">{JSON.stringify(webSchema)}</script>
      </Helmet>
      <Layout>
      <BannerSection />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Crypto Payment Gateway Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyStart />
      <ServiceProvider />
      <WhatsNew />
      <TopSelling />
      <Useradmin />
      <Integrations />
      <BenifitsOf />
      <PaymentOption />
      <Pickcrypto />
      <Securefeaof />
      <DevProcess />
      <Whychoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      </Layout>
  </section>
)

export default Cryptopayment
