import React from "react"

class ServiceProvider extends React.Component {

  render() {
    return (
      <section className="dapps payment pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">Our Crypto Payment Gateway <span className="bluecolor">Development Services</span></h2>
            <p className='text-center'>As a leading Cryptocurrency payment gateway development company, we specialize in creating robust and secure cryptocurrency payment gateway solutions. Our team of experts offers comprehensive crypto payment gateway development services tailored to meet the unique needs of your business.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Crypto Payment Gateway App</h3>
                            <p className='pharagraph'>We comprise a skilled set of developers who are well-versed in offering the payment gateway in mobile version. We ensure to provide an efficient and reliable transaction experience for your crypto users.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multi-cryptocurrency payment gateway</h3>
                            <p className='pharagraph'>Our solution allows businesses to accept various digital assets through a unified platform, simplifying transactions and improving efficiency. It ensures safe transactions and valuable insights which helps to stay ahead of the competition.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>API Plugins</h3>
                            <p className='pharagraph'>For enhanced security, we offer seamless API plugin implementation in our crypto payment gateways. Our technical team ensures to offer potential API plugins that will provide your crypto users with exponential trading experience.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Blockchain Payment</h3>
                            <p className='pharagraph'>We offer to create an exemplary payment processor platform for secure payments from all blockchain networks. We ensure the safeguarding of the transaction data with high-end support and decentralization.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Crypto Wallet Integration</h3>
                            <p className='pharagraph'>Our crypto payment gateway platform allows integration of advanced-level cryptocurrency wallets which ensures secured crypto trading. It protects user’s data from scams, hacks, and data theft.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multilingual Support</h3> 
                            <p className='pharagraph'>Our team offers crypto payment gateway development with multiple language support to grab the user's attention. This strengthens the communication between the platform administrator and crypto users.</p>
                        </div>
                    </div>
          </div>
          <div className="mt-3 text-center">
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn">Show Me A Free Demo</a>
          </div>
        </div>
      </section>
    )
  }
}

export default ServiceProvider
