import React from 'react'




const WhatsNew =()=> {

    return (
      <section className="whatis whatsnew pb-0 pt-100">
        <div className="container">
            <div className="heading-h2 text-center"><h2><span className='bluecolor'>What’s New?</span> Our USP - White label Crypto Payment Gateway</h2></div>
            <div className="text-center">
              <p className="pharagraph" >Kickstart your business with our white-label crypto payment gateway development services instantly. We offer a seamless solution for entrepreneurs to launch a sophisticated payment gateway business. As a leading cryptocurrency payment gateway development company, Coinsclone offers a top-tier solution designed to help you swiftly establish a successful crypto payment gateway application. Our ready-made solution allows for extensive customization, enabling you to tailor both the front-end user interface and back-end functionality to your specific requirements. With the flexibility to incorporate any desired features, our team of expert blockchain engineers can adapt and enhance the platform to align with your vision. Our rapid deployment approach ensures that you can have a fully operational crypto payment gateway up and running in just a few days. We are committed to timely delivery, ensuring that your project is completed on schedule to meet your business needs efficiently and effectively.
              </p>
            </div>
            <div className='mw850 why-card'>
                <p>Ready-made Customizable Platform</p>
                <p>Full Control Over the Branding</p>
                <p>Tailored with Advanced Features</p>
                <p>Designed for Fast Deployment</p>
            </div>
        </div>
      </section>
    )
}

export default WhatsNew